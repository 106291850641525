import HeroSlider, { Slide, MenuNav } from "hero-slider";

const slide1 = "https://trailblazer.caltech.edu/images/spacecraft3.jpg";
const slide2 = "https://trailblazer.caltech.edu/images/LTMShipped.jpg"
const slide3 = "https://trailblazer.caltech.edu/images/news/HVM3_ATLO_2.jpg";
const slide4 = "https://trailblazer.caltech.edu/images/HVM3FPA.jpg";
const slide5 = "https://trailblazer.caltech.edu/images/news/HVM3_ATLO_1.jpg";

export default function BasicSlider() {
  return (
    <HeroSlider
      height={"calc((var(--vh, 1vh) * 100) - 65px)"}
      autoplay
      controller={{
        initialSlide: 1,
        slidingDuration: 1200,
        slidingDelay: 200,
        onSliding: (nextSlide) =>
          console.debug("onSliding(nextSlide): ", nextSlide),
        onBeforeSliding: (previousSlide, nextSlide) =>
          console.debug(
            "onBeforeSliding(previousSlide, nextSlide): ",
            previousSlide,
            nextSlide
          ),
        onAfterSliding: (nextSlide) =>
          console.debug("onAfterSliding(nextSlide): ", nextSlide)
      }}
    >


      <Slide
        label="Lunar Trailblazer is Now Launch-Ready (Q4 2024)"
        background={{
          backgroundImageSrc: slide1
        }}
      >
        <a href="https://trailblazer.caltech.edu/objectives.html">
          <div class="headline-overlay">
            <h2 style={{ color: "white" }}>
            Lunar Trailblazer is Now Launch-Ready (Q4 2024)
            </h2>
            <p style={{ color: "white" }}>
              Lunar Trailblazer achieves critical advancements for science as a
              lower-budget mission, and will launch as a secondary payload on IM-2, 
              the second lunar lander mission by Intuitive Machines.
            </p>
          </div>
        </a>
        <a href="https://trailblazer.caltech.edu/objectives.html">
          <div class="overlay-hyperlink"></div>
        </a>
      </Slide>

      <Slide
        label="LTM Instrument Arrives with Lunar Trailblazer at Lockheed Martin"
        background={{
          backgroundImageSrc: slide2
        }}
      >
        <a href="https://www.jpl.nasa.gov/news/nasas-lunar-trailblazer-gets-final-payload-for-moon-water-hunt/">
          <div class="headline-overlay">
            <h2 style={{ color: "white" }}>
              LTM Instrument Integrated with Lunar Trailblazer at Lockheed Martin
            </h2>
            <p style={{ color: "white" }}>
              University of Oxford's LTM instrument integrated with 
              spacecraft and ready to make thermal measurements on 
              the Moon.
            </p>
          </div>
        </a>
        <a href="https://www.jpl.nasa.gov/news/nasas-lunar-trailblazer-gets-final-payload-for-moon-water-hunt/">
          <div class="overlay-hyperlink"></div>
        </a>
      </Slide>
      
      <Slide
        label="HVM3 Instrument Integrated with Lunar Trailblazer"
        background={{
          backgroundImageSrc: slide3
        }}
      >
        <a href="https://trailblazer.caltech.edu/news/HVM3Delivery.html">
          <div class="headline-overlay">
            <h2 style={{ color: "white" }}>
              HVM<sup>3</sup> Instrument Integrated with Lunar Trailblazer
            </h2>
            <p style={{ color: "white" }}>
              Lunar Trailblazer's HVM<sup>3</sup> instrument, built at NASA's
              Jet Propulsion Laboratory in Southern California, has been
              delivered and integrated with the satellite at Lockheed Martin
              Space in Colorado.
            </p>
          </div>
        </a>
        <a href="https://trailblazer.caltech.edu/news/HVM3Delivery.html">
          <div class="overlay-hyperlink"></div>
        </a>
      </Slide>

      <Slide
        label="The Science Behind HVM3’s Search for Lunar Water Explained"
        background={{
          backgroundImageSrc: slide4
        }}
      >
        <a href="https://trailblazer.caltech.edu/news/HVM3Animation.html">
          <div class="headline-overlay">
            <h2 style={{ color: "white" }}>The Science Behind HVM3’s Search for Lunar Water Explained</h2>
            <p style={{ color: "white" }}>
            Lunar Trailblazer intern and Pasadena City College student 
            Emily Felder explains how HVM3’s advanced design will locate 
            and differentiate lunar water.
            </p>
          </div>
        </a>
        <a href="https://trailblazer.caltech.edu/news/HVM3Animation.html">
          <div class="overlay-hyperlink"></div>
        </a>
      </Slide>

      <Slide
        label="Lunar Trailblazer Completes Environmental Testing at Lockheed Martin Space"
        background={{
          backgroundImageSrc: slide5
        }}
      >
        <a href="https://blogs.nasa.gov/trailblazer/2024/09/11/nasas-lunar-trailblazer-spacecraft-completes-environmental-testing/">
          <div class="headline-overlay">
            <h2 style={{ color: "white" }}>
              Lunar Trailblazer Completes Enivornmental Testing at Lockheed Martin Space
            </h2>
            <p style={{ color: "white" }}>
              NASA’s Lunar Trailblazer spacecraft recently completed the shaking, chilling, 
              baking, and other testing needed to ensure it can survive launch and the harsh 
              conditions of space.
            </p>
          </div>
        </a>
        <a href="https://blogs.nasa.gov/trailblazer/2024/09/11/nasas-lunar-trailblazer-spacecraft-completes-environmental-testing/">
          <div class="overlay-hyperlink"></div>
        </a>
      </Slide>

      <MenuNav />
    </HeroSlider>
  );
}
